import React, { useState } from "react";

import { useTranslation } from "react-i18next";

function SynthTradeAccount () {

    const [isHovered, setIsHovered] = useState(false);
    const handleMouseEnter = () => {
        setIsHovered(true);
      };
    
      const handleMouseLeave = () => {
        setIsHovered(false);
      };

      const { t } = useTranslation();

    return(
        <div className="bg-[#D9DFFF33]">
           <div className="max-w-[1280px] mx-auto">
                <div className="flex flex-col xl:flex-row items-center justify-between pt-[50px] xl:pt-[100px] pb-[50px] xl:pb-[100px]">
                    <div className="mx-[20px] xl:mx-0">
                        <p className="text-[18px] xl:text-[30px] text-[#637BFF] font-[800]">{t('main.30')}</p>
                        <p className="text-[14px] xl:text-[16px] font-[300] mt-[30px] max-w-[525px]">{t('main.31')}</p>
                        <p className="text-[14px] xl:text-[16px] font-[300] mt-[15px] max-w-[525px]">{t('main.32')}</p>
                        <a href="https://user.snb-capital.limited/login">
                        <div 
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        className="flex w-[200px] buttonmain cursor-pointer mt-[40px] items-center"
                        >
                            <div style={{ width: isHovered ? '100%' : '38.774px' }} className={`z-50 transition-all duration-500 h-[38.774px] rounded-full bg-[#181719] flex items-center justify-center`}>
                                <svg className="transition-all duration-500" style={{ rotate: isHovered ? '-135deg' : '0deg' }} xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                    <g clip-path="url(#clip0_1175_524)">
                                        <path d="M8.04175 11.7496L14.9945 4.79688L16.1367 5.93911L9.18398 12.8919H15.312V14.5075H6.42615V5.62164H8.04175V11.7488V11.7496Z" fill="white"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1175_524">
                                            <rect width="19.3872" height="19.3872" fill="white" transform="translate(0.771606 0.771484)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <p className={`text-[#080808] roboto text-[16px] ml-[45px] absolute`}>{t('main.25')}</p>
                        </div>
                        </a>
                    </div>
                    <div className="flex justify-center items-center flex-col xl:flex-row gap-[25px] xl:gap-0 mt-[40px] xl:mt-0">
                        <div className="flex flex-col gap-[25px]">
                            <div className="w-[272px] h-[100px] xl:h-[232px] rounded-[20px] bg-white border-b-[10px] border-[#F89CC7] border-solid flex flex-col items-center justify-center xl:items-start xl:justify-normal">
                                <p className="text-[16px] xl:text-[18px] text-black font-[700] open-sans xl:mt-[36px] xl:ml-[30px]">Basic</p>
                                <a href="https://user.snb-capital.limited/login"><p className="text-[16px] xl:text-[18px] text-black hover:text-[#637BFF] font-[600] open-sans mt-[20px] xl:ml-[30px] underline">{t('main.33')}</p></a>   
                            </div>
                            <div className="w-[272px] h-[100px] xl:h-[232px] rounded-[20px] bg-white border-b-[10px] border-[#F89CC7] border-solid flex flex-col items-center justify-center xl:items-start xl:justify-normal">
                                <p className="text-[16px] xl:text-[18px] text-black font-[700] open-sans xl:mt-[36px] xl:ml-[30px]">Silver {t('main.34')}</p>
                                <a href="https://user.snb-capital.limited/login"><p className="text-[16px] xl:text-[18px] text-black hover:text-[#637BFF] font-[600] open-sans mt-[20px] xl:ml-[30px] underline">{t('main.33')}</p></a>   
                            </div>
                        </div>
                        <div className="flex flex-col gap-[25px] xl:ml-[55px]">
                            <div className="w-[272px] h-[100px] xl:h-[232px] rounded-[20px] bg-white border-b-[10px] border-[#F89CC7] border-solid flex flex-col items-center justify-center xl:items-start xl:justify-normal">
                                <p className="text-[16px] xl:text-[18px] text-black font-[700] open-sans xl:mt-[36px] xl:ml-[30px]">Gold {t('main.34')}</p>
                                <a href="https://user.snb-capital.limited/login"><p className="text-[16px] xl:text-[18px] text-black hover:text-[#637BFF] font-[600] open-sans mt-[20px] xl:ml-[30px] underline">{t('main.33')}</p></a>   
                            </div>
                            <div className="w-[272px] h-[100px] xl:h-[232px] rounded-[20px] bg-white border-b-[10px] border-[#F89CC7] border-solid flex flex-col items-center justify-center xl:items-start xl:justify-normal">
                                <p className="text-[16px] xl:text-[18px] text-black font-[700] open-sans xl:mt-[36px] xl:ml-[30px]">Platinum {t('main.34')}</p>
                                <a href="https://user.snb-capital.limited/login"><p className="text-[16px] xl:text-[18px] text-black hover:text-[#637BFF] font-[600] open-sans mt-[20px] xl:ml-[30px] underline">{t('main.33')}</p></a>   
                            </div>
                            <div className="w-[272px] h-[100px] xl:h-[232px] rounded-[20px] bg-white border-b-[10px] border-[#F89CC7] border-solid flex flex-col items-center justify-center xl:items-start xl:justify-normal">
                                <p className="text-[16px] xl:text-[18px] text-black font-[700] open-sans xl:mt-[36px] xl:ml-[30px]">Diamond {t('main.34')}</p>
                                <a href="https://user.snb-capital.limited/login"><p className="text-[16px] xl:text-[18px] text-black hover:text-[#637BFF] font-[600] open-sans mt-[20px] xl:ml-[30px] underline">{t('main.33')}</p></a>   
                            </div>
                        </div>
                    </div>
                </div>
           </div>
        </div>
    );
}

export default SynthTradeAccount;