import React, { useEffect } from "react";

import LazyLoad from "react-lazyload";

import Main from '../components/FeesCommissions/Main'
import JoinSynth from "../components/Home/JoinSynth";
import PlatformFees from "../components/FeesCommissions/PlatformFees";
import AccountBased from "../components/FeesCommissions/AccountBased";
import ExceptionalFees from "../components/FeesCommissions/ExceptionalFees";


function FeesCommissions () {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div className="pt-[80px]">
            <Main/>
            <div className="mt-[80px] xl:mt-[260px]"></div>
            <LazyLoad><PlatformFees/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
            <LazyLoad><AccountBased/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
            <LazyLoad><ExceptionalFees/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
            <LazyLoad><JoinSynth/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
        </div>
    );
}

export default FeesCommissions;