import React from "react";

import { useTranslation } from "react-i18next";

function ExceptionalFees () {

    const { t } = useTranslation();

    return(
        <div>
            <div className="max-w-[1280px] xl:mx-auto mx-[20px]">
                <p className="text-[#637BFF] text-[20px] xl:text-[30px] font-[800] open-sans max-w-[408px]">{t('add.43')}</p>
                <div className="flex flex-col xl:flex-row justify-between mt-[20px] xl:mt-[30px]">
                    <div>
                        <p className="open-sans font-[300] text-[14px] xl:text-[16px] xl:max-w-[525px]">{t('add.44')}</p>
                        <p className="open-sans font-[300] text-[14px] xl:text-[16px] max-w-[525px] mt-[15px]">{t('add.45')}</p>
                    </div>
                    <div className="mt-[20px] xl:mt-0">
                        <p className="open-sans font-[300] text-[14px] xl:text-[16px] xl:max-w-[525px]">{t('add.46')}</p>
                        <p className="open-sans font-[300] text-[14px] xl:text-[16px] xl:max-w-[525px] mt-[15px]">{t('add.47')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ExceptionalFees;