import React from "react";
import classes from './Speaker.module.scss';
import { Trans, useTranslation } from "react-i18next";
import image from '../../../assets/img/EducationPage/Speaker/image.png';
import cmsa from '../../../assets/img/EducationPage/cmsa.jpg';
import award_01 from '../../../assets/img/EducationPage/Speaker/award_01.png';
import award_02 from '../../../assets/img/EducationPage/Speaker/award_02.png';

const Speaker = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container pb-[80px] xl:pb-[160px]">
            <div className={classes.speaker}>
                <div className={classes.content}>
                    <h2 className={`${classes.title} font-48`}>
                        {t('education_title_05')}
                    </h2>
                    <p className={`${classes.text} font-20`}>
                        <Trans components={{ a: <a href={cmsa} target="_blank"></a> }}>
                            {t('education_text_06')}
                        </Trans>
                    </p>
                </div>
                <img className={classes.image} src={image} alt='' />
            </div>
            <div className={`${classes.award} mt`}>
                <img className={classes.awardImage} src={award_01} alt='' />
                <img className={classes.awardImage} src={award_02} alt='' />
            </div>
        </section>
    );
};

export default Speaker;
