import React, { useState } from "react";

import img from '../../img/AccountTypes/main.png'

import { useTranslation } from "react-i18next";

function Main () {

    const [isHovered, setIsHovered] = useState(false);
    const handleMouseEnter = () => {
        setIsHovered(true);
      };
    
      const handleMouseLeave = () => {
        setIsHovered(false);
      };

      const { t } = useTranslation();

    return(
        <div>
           <div className="max-w-[1280px] mx-auto">
               <div className="flex mt-[50px] xl:mt-[100px] justify-between flex-col xl:flex-row mx-[20px] xl:mx-0">
                    <div>
                        <p className="text-[#637BFF] open-sans font-[800] text-[18px] xl:text-[30px]">{t('accounttypes.1')}</p>
                        <p className="text-[14px] xl:text-[16px] open-sans font-[300] xl:max-w-[525px] mt-[20px] xl:mt-[30px]">{t('accounttypes.2')}</p>
                        <p className="text-[14px] xl:text-[16px] open-sans font-[300] xl:max-w-[525px] mt-[15px]">{t('accounttypes.3')}</p>
                        <a href="https://user.snb-capital.limited/login">
                        <div 
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            className="flex w-[200px] buttonmain cursor-pointer mt-[40px] items-center"
                        >
                            <div style={{ width: isHovered ? '100%' : '38.774px' }} className={`z-50 transition-all duration-500 h-[38.774px] rounded-full bg-[#181719] flex items-center justify-center`}>
                                <svg className="transition-all duration-500" style={{ rotate: isHovered ? '-135deg' : '0deg' }} xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                    <g clip-path="url(#clip0_1175_524)">
                                        <path d="M8.04175 11.7496L14.9945 4.79688L16.1367 5.93911L9.18398 12.8919H15.312V14.5075H6.42615V5.62164H8.04175V11.7488V11.7496Z" fill="white"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1175_524">
                                            <rect width="19.3872" height="19.3872" fill="white" transform="translate(0.771606 0.771484)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <p className={`text-[#080808] roboto text-[16px] ml-[45px] absolute`}>{t('accounttypes.4')}</p>
                        </div>
                        </a>
                    </div>
                    <div>
                       <img src={img} alt="" className="mt-[20px] xl:mt-0 mx-auto"/> 
                    </div>
               </div>
           </div>
        </div>
    );
}

export default Main;