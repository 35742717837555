import React, { useState } from "react";

import { useTranslation } from "react-i18next";

const data = [
    {
        name: 'accounttypes.9',
        text: [
            'accounttypes.10',
            'accounttypes.11',
            'accounttypes.12',
            'accounttypes.13',
            'accounttypes.14'
        ]
    },
    {
        name: 'accounttypes.15',
        text: [
            'accounttypes.16',
            'accounttypes.17',
            'accounttypes.18',
            'accounttypes.19',
            'accounttypes.20'
        ]
    },
    {
        name: 'accounttypes.21',
        text: [
            'accounttypes.22',
            'accounttypes.23',
            'accounttypes.24',
            'accounttypes.25',
            'accounttypes.26'
        ]
    },
    {
        name: 'accounttypes.27',
        text: [
            'accounttypes.28',
            'accounttypes.29',
            'accounttypes.30',
            'accounttypes.31',
            'accounttypes.32',
            'accounttypes.33'
        ]
    },
    {
        name: 'accounttypes.34',
        text: [
            'accounttypes.35',
            'accounttypes.36',
            'accounttypes.37',
            'accounttypes.38',
            'accounttypes.39',
            'accounttypes.40'
        ]
    },
]

function TypesOfAccounts () {

    const [activeIndex, setactiveIndex] = useState(0);

    const handleSlideClick = (index) => {
        setactiveIndex(index);
    };

    const [isHovered, setIsHovered] = useState(false);
    const handleMouseEnter = () => {
        setIsHovered(true);
      };
    
      const handleMouseLeave = () => {
        setIsHovered(false);
      };

      const { t } = useTranslation();

    return(
        <div>
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto">
                <div className="flex flex-col xl:flex-row justify-between">
                    <div>
                        <p className="text-[#637BFF] open-sans font-[800] text-[18px] xl:text-[30px]">{t('accounttypes.5')}</p>
                        <p className="text-[14px] xl:text-[16px] open-sans font-[300] xl:max-w-[525px] mt-[20px] xl:mt-[30px]">{t('accounttypes.6')}</p>
                        <p className="text-[14px] xl:text-[16px] open-sans font-[300] xl:max-w-[525px] mt-[15px]">{t('accounttypes.7')}</p>
                        <a href="https://user.snb-capital.limited/login">
                        <div 
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            className="flex w-[200px] buttonmain cursor-pointer mt-[40px] items-center"
                        >
                            <div style={{ width: isHovered ? '100%' : '38.774px' }} className={`z-50 transition-all duration-500 h-[38.774px] rounded-full bg-[#181719] flex items-center justify-center`}>
                                <svg className="transition-all duration-500" style={{ rotate: isHovered ? '-135deg' : '0deg' }} xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                    <g clip-path="url(#clip0_1175_524)">
                                        <path d="M8.04175 11.7496L14.9945 4.79688L16.1367 5.93911L9.18398 12.8919H15.312V14.5075H6.42615V5.62164H8.04175V11.7488V11.7496Z" fill="white"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1175_524">
                                            <rect width="19.3872" height="19.3872" fill="white" transform="translate(0.771606 0.771484)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <p className={`text-[#080808] roboto text-[16px] ml-[45px] absolute`}>{t('accounttypes.4')}</p>
                        </div>
                        </a>
                    </div>
                    <div className="mt-[20px] xl:mt-0 flex flex-col xl:flex-row">
                        <div className="mt-[50px] flex xl:flex-col flex-row flex-wrap gap-[10px] justify-center xl:justify-start">
                            {data.map((data, index) => (
                                <div key={index} className={`cursor-pointer flex items-center justify-center w-[92px] h-[40px] rounded-[10px] xl:rounded-none xl:rounded-l-[10px] ${ activeIndex === index ? 'bglinear' : 'bg-white border' }`} onClick={() => handleSlideClick(index)}>
                                    <p className="text-[14px] xl:text-[16px] font-[600] open-sans">{t(data.name)}</p> 
                                </div>
                            ))}
                        </div>
                        <div className="xl:w-[414px] h-[450px] rounded-[50px] bg-[#F7F9FF] shadowtypes mt-[20px] xl:mt-0">
                            <p className="font-[800] text-[#222B40] text-center mt-[50px] mb-[20px] text-[25px] open-sans">{t(data[activeIndex].name)} {t('accounttypes.8')}</p>
                            {data[activeIndex].text.map((item, index) => (
                                <p key={index} className="text-[#222B40] text-[14px] xl:text-[16px] open-sans font-[400] open-sans mt-[15px] mx-[30px]">{t(item)}</p>                 
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TypesOfAccounts;