import React, {useState} from "react";

import { useTranslation } from "react-i18next";

import main from '../../img/AboutUs/main.png';

function Main () {

    const [isHovered, setIsHovered] = useState(false);
    const handleMouseEnter = () => {
        setIsHovered(true);
      };
    
      const handleMouseLeave = () => {
        setIsHovered(false);
      };

      const { t } = useTranslation();
    
    return(
        <div className="">
           <div className="max-w-[1280px] mx-auto">
                <div className="xl:h-[459px] flex flex-col xl:flex-row xl:justify-between items-center xl:items-start w-auto bg-black rounded-[50px] xl:mt-[70px] mx-[20px] xl:mx-0 relative">
                    <div className="xl:ml-[70px] mt-[45px] mx-[20px] xl:mx-0">
                        <p className="max-w-[500px] open-sans text-[#A8B5F9] font-extrabold text-[18px] xl:text-[30px]">{t('supportfaq.79')}</p>
                        <p className="max-w-[525px] open-sans text-white font-light text-[14px] xl:text-[16px] mt-[30px]">{t('supportfaq.80')}</p>
                        <p className="max-w-[525px] open-sans text-white font-light text-[14px] xl:text-[16px] mt-[15px]">{t('supportfaq.81')}</p>
                        <a href="https://user.snb-capital.limited/login">
                        <div 
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        className="flex w-[200px] buttonmain cursor-pointer mt-[40px] items-center"
                        >
                            <div style={{ width: isHovered ? '100%' : '38.774px' }} className={`z-50 transition-all duration-500 h-[38.774px] rounded-full bg-[#181719] flex items-center justify-center`}>
                                <svg className="transition-all duration-500" style={{ rotate: isHovered ? '-135deg' : '0deg' }} xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                    <g clip-path="url(#clip0_1175_524)">
                                        <path d="M8.04175 11.7496L14.9945 4.79688L16.1367 5.93911L9.18398 12.8919H15.312V14.5075H6.42615V5.62164H8.04175V11.7488V11.7496Z" fill="white"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1175_524">
                                            <rect width="19.3872" height="19.3872" fill="white" transform="translate(0.771606 0.771484)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <p className={`text-[#080808] roboto text-[16px] ml-[45px] absolute`}>Start trading!</p>
                        </div>
                        </a>
                    </div>
                    <div>
                        <img src={main} alt="" className="xl:absolute right-0 bottom-0 mt-[20px] xl:mt-0 pb-[20px] xl:pb-0"/>
                    </div>
                </div>
           </div>
        </div>
    );
}

export default Main;