import React from "react";

import { useTranslation } from "react-i18next";

import img1 from '../../img/AboutUs/img.png'
import img2 from '../../img/AboutUs/img2.png'
import img3 from '../../img/AboutUs/img3.png'

function OurAchievements () {

    const { t } = useTranslation();

    return(
        <div>
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col">
                <p className="text-[#637BFF] open-sans font-[800] text-[18px] xl:text-[30px] xl:max-w-[525px]">{t('supportfaq.93')}</p>
                <div className="flex justify-between flex-col xl:flex-row mt-[20px] xl:mt-[30px]">
                    <div>
                        <p className="text-[14px] xl:text-[16px] open-sans font-[300] xl:max-w-[525px]">{t('supportfaq.94')}</p>
                    </div>
                    <div>
                        <p className="text-[14px] xl:text-[16px] open-sans font-[300] xl:max-w-[525px]">{t('supportfaq.95')}</p>
                    </div>
                </div>
                <div className="mt-[30px] xl:mt-[90px] flex flex-col xl:flex-row justify-around">
                    <div className="flex flex-col justify-between">
                        <div className="flex flex-col xl:flex-row justify-between">
                            <div className="bg-[#7A8FFF] xl:w-[370px] h-[360px] relative">
                                <img src={img1} alt="" className="absolute"/>
                                <div className="mx-[20px] mt-[40px]">
                                    <p className="open-sans font-[600] text-[#FEFDF7] text-[16px] xl:text-[23px]">{t('supportfaq.96')}</p>
                                    <p className="open-sans font-[300] text-[#FEFDF7] text-[14px] xl:text-[16px] mt-[15px] xl:mt-[30px]">{t('supportfaq.97')}</p>
                                </div> 
                            </div>
                            <div className="bg-[#DBDBDB] xl:w-[370px] h-[360px] relative mt-[20px] xl:mt-0">
                                <img src={img1} alt="" className="absolute"/>
                                <div className="mx-[20px] mt-[40px]">
                                    <p className="open-sans font-[600] text-[#000] text-[16px] xl:text-[23px]">{t('supportfaq.98')}</p>
                                    <p className="open-sans font-[300] text-[#000] text-[14px] xl:text-[16px] mt-[15px] xl:mt-[30px]">{t('supportfaq.99')}</p>
                                </div> 
                            </div>
                        </div>
                        <div className="bg-[#F89CC7] xl:w-[795px] h-[292px] relative mt-[20px] xl:mt-0">
                            <img src={img2} alt="" className="absolute right-0"/>
                            <div className="mx-[20px] pt-[40px]">
                                <p className="open-sans font-[600] text-[#000] text-[16px] xl:text-[23px]">{t('supportfaq.100')}</p>
                                <p className="open-sans font-[300] text-[#000] text-[14px] xl:text-[16px] mt-[15px] xl:mt-[30px] max-w-[393px]">{t('supportfaq.101')}</p>
                            </div> 
                        </div>
                    </div>
                    <div>
                        <div className="bg-[#7A8FFF] xl:w-[370px] h-[292px] xl:h-[707px] relative mt-[20px] xl:mt-0">
                            <img src={img3} alt="" className="absolute bottom-0 right-0"/>
                            <div className="mx-[20px] pt-[40px]">
                                <p className="open-sans font-[600] text-[#FEFDF7] text-[16px] xl:text-[23px]">{t('supportfaq.102')}</p>
                                <p className="open-sans font-[300] text-[#FEFDF7] text-[14px] xl:text-[16px] mt-[15px] xl:mt-[30px]">{t('supportfaq.103')}</p>
                                <a href="https://user.snb-capital.limited/login">
                                <div className="bg-white w-[165px] h-[45px] flex justify-center items-center rounded-[20px] cursor-pointer mt-[20px] xl:mt-[50px]">
                                    <p className="text-[#222B40] open-sans font-[800] text-[14px] xl:text-[16px]">{t('supportfaq.104')}</p>
                                </div>
                                </a>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OurAchievements;