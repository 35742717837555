import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import i18n from "i18next";
import Cookies from "js-cookie";

import logo from '../img/logo.svg';

import lock from '../assets/img/lock.svg'

import MobileMenu from './MobileMenu'

function Header() {
    const location = useLocation();

    const { t } = useTranslation();

    const languages = [
        { value: 'en', label: 'EN' },
        { value: 'ru', label: 'RU' },
        { value: 'md', label: 'MD' },
        { value: 'ro', label: 'RO' },
        { value: 'de', label: 'DE' },
        { value: 'pl', label: 'PL' },
        { value: 'ar', label: 'AR' },
    ]

    const storedLang = Cookies.get("i18next");
    const [currentLang, setCurrentLang] = useState(storedLang || 'en');

    useEffect(() => {
        if (storedLang && storedLang !== currentLang) {
            setCurrentLang(storedLang);
            i18n.changeLanguage(storedLang);
        }
        // eslint-disable-next-line
    }, [storedLang]);
    

    const handleChangeLang = (selectedLang) => {
        setCurrentLang(selectedLang.value);
        i18n.changeLanguage(selectedLang.value);
        Cookies.set("i18next", selectedLang.value);
    };

    return (
        <div className="fixed top-0 left-0 w-full bg-white z-[999]">
            <div className="max-w-[1280px] mx-auto">
                <div className="flex h-[80px] justify-between items-center mx-[20px] xl:mx-0">
                    <Link to="/"><img src={logo} alt="" className="h-[50px]"/></Link>
                    <div className={`xl:flex items-center hidden ${currentLang === 'ro' ? '!gap-[30px]' : 'gap-[30px]'}  ${currentLang === 'ru' ? '!gap-[20px]' : 'gap-[30px]'}`}>
                        <Link to="/tradinginstruments"><p className={`text-[15px] textcolor roboto ${location.pathname === '/tradinginstruments' ? 'font-medium' : 'font-light'}`}>{t('header.1')}</p></Link>
                        <Link to="/fees"><p className={`text-[15px] textcolor roboto ${location.pathname === '/fees' ? 'font-medium' : 'font-light'}`}>{t('header.2')}</p></Link>
                        <Link to="/account"><p className={`text-[15px] textcolor roboto ${location.pathname === '/account' ? 'font-medium' : 'font-light'}`}>{t('header.3')}</p></Link>
                        <Link to="/support"><p className={`text-[15px] textcolor roboto ${location.pathname === '/support' ? 'font-medium' : 'font-light'}`}>{t('header.4')}</p></Link>
                        <Link to="/about"><p className={`text-[15px] textcolor roboto ${location.pathname === '/about' ? 'font-medium' : 'font-light'}`}>{t('header.5')}</p></Link>
                        <div className="flex items-center gap-[5px]">
                            <Link to="/edu"><p className={`text-[15px] textcolor roboto ${location.pathname === '/edu' ? 'font-medium' : 'font-light'}`}>{t('header.edu')}</p></Link>
                            <img src={lock} alt="" />
                        </div>
                        <Dropdown
                            options={languages}
                            placeholder="EN"
                            onChange={handleChangeLang}
                            value={languages.find(lang => lang.value === currentLang)}
                        />
                        <Link to="https://user.snb-capital.limited/login">
                            <div className="buttonheader cursor-pointer">
                                <p className="rubik text-white text-[16px]">{t('header.6')}</p>
                            </div>
                        </Link>
                    </div>
                    <div className="flex xl:hidden items-center gap-[20px]">
                        <Dropdown
                            options={languages}
                            placeholder="EN"
                            onChange={handleChangeLang}
                            value={languages.find(lang => lang.value === currentLang)}
                        />
                        <MobileMenu/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;
