import React, { useEffect } from "react";

import LazyLoad from "react-lazyload";

import Main from '../components/SupportFAQ/Main'
import JoinSynth from "../components/Home/JoinSynth";
import HowWeCanHelp from "../components/SupportFAQ/HowWeCanHelp";
import CustomerSupport from "../components/SupportFAQ/CustomerSupport";
import PlatformWalkthroughs from "../components/SupportFAQ/PlatformWalkthroughs";
import CommunityForums from "../components/SupportFAQ/CommunityForums";
import PlatformWalkthroughsSecond from "../components/SupportFAQ/PlatformWalkthroughsSecond";

function SupportFAQ () {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div className="pt-[80px]">
            <Main/>
            <div className="mt-[80px] xl:mt-[260px]"></div>
            <LazyLoad><HowWeCanHelp/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
            <LazyLoad><CustomerSupport/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
            <LazyLoad><PlatformWalkthroughs/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
            <LazyLoad><CommunityForums/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
            <LazyLoad><PlatformWalkthroughsSecond/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
            <LazyLoad><JoinSynth/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
        </div>
    );
}

export default SupportFAQ;