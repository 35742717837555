import React, { useEffect } from "react";

import LazyLoad from "react-lazyload";

import Main from '../components/AccountTypes/Main'
import JoinSynth from "../components/Home/JoinSynth";
import TypesOfAccounts from "../components/AccountTypes/TypesOfAccounts";
import WhatTypeShould from "../components/AccountTypes/WhatTypeShould";


function AccountTypes () {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div className="pt-[80px]">
            <Main/>
            <div className="mt-[80px] xl:mt-[260px]"></div>
            <LazyLoad><TypesOfAccounts/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
            <LazyLoad><WhatTypeShould/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
            <LazyLoad><JoinSynth/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
        </div>
    );
}

export default AccountTypes;