import React from "react";

import { useTranslation } from "react-i18next";

import mm from '../../img/Home/whychoose1.png'
import mmm from '../../img/Home/main4.png'
import mmmm from '../../img/Home/main3.png'

import img from '../../img/AboutUs/iphone.png'

function InfoBlocks () {

    const { t } = useTranslation();

    return(
        <div>
           <div className="max-w-[1280px] mx-[20px] xl:mx-auto">
               <div className="flex mt-[50px] xl:mt-[100px] justify-between flex-col xl:flex-row">
                    <div>
                        <p className="text-[#637BFF] open-sans font-[800] text-[18px] xl:text-[30px] xl:max-w-[525px]">{t('supportfaq.82')}</p>
                        <p className="text-[14px] xl:text-[16px] open-sans font-[300] xl:max-w-[525px] mt-[20px] xl:mt-[30px]">{t('supportfaq.83')}</p>
                        <p className="text-[14px] xl:text-[16px] open-sans font-[300] xl:max-w-[525px] mt-[15px]">{t('supportfaq.84')}</p>
                    </div>
                    <div>
                       <img src={img} alt="" className="mt-[20px] xl:mt-0 mx-auto"/> 
                    </div>
               </div>
               <div className="mt-[80px] xl:mt-[160px]">
                    <p className="text-[#637BFF] open-sans font-[800] text-[18px] xl:text-[30px] xl:max-w-[525px]">{t('supportfaq.85')}</p>
                    <div className="flex mt-[20px] xl:mt-[30px] justify-between flex-col xl:flex-row">
                        <div>
                            <p className="text-[14px] xl:text-[16px] open-sans font-[300] xl:max-w-[525px]">{t('supportfaq.86')}</p>
                            <p className="text-[14px] xl:text-[16px] open-sans font-[300] xl:max-w-[525px] mt-[15px]">{t('supportfaq.87')}</p>
                        </div>
                        <div className="relative">
                            <p className="text-[14px] xl:text-[16px] open-sans font-[300] xl:max-w-[525px]">{t('supportfaq.88')}</p>
                            <img src={mm} alt="" className="absolute bottom-0 left-[100px] hidden xl:block"/>
                            <img src={mmm} alt="" className="absolute right-0 mt-[10px] hidden xl:block"/>
                        </div>
                    </div>
               </div>
               <div className="mt-[80px] xl:mt-[160px]">
                    <p className="text-[#637BFF] open-sans font-[800] text-[18px] xl:text-[30px] xl:max-w-[525px]">{t('supportfaq.89')}</p>
                    <div className="flex mt-[20px] xl:mt-[30px] justify-between flex-col xl:flex-row">
                        <div>
                            <p className="text-[14px] xl:text-[16px] open-sans font-[300] xl:max-w-[525px]">{t('supportfaq.90')}</p>
                            <p className="text-[14px] xl:text-[16px] open-sans font-[300] xl:max-w-[525px] mt-[15px]">{t('supportfaq.91')}</p>
                        </div>
                        <div className="relative">
                            <p className="text-[14px] xl:text-[16px] open-sans font-[300] xl:max-w-[525px]">{t('supportfaq.92')}</p>
                            <img src={mmmm} alt="" className="absolute bottom-0 right-[200px] hidden xl:block"/>
                            <img src={mm} alt="" className="absolute left-0 mt-[10px] hidden xl:block"/>
                        </div>
                    </div>
               </div>
           </div>
        </div>
    );
}

export default InfoBlocks;