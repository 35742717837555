import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom"

import { useTranslation } from "react-i18next";

import lock from '../assets/img/lock.svg'

import logo from "../img/logo.svg";

function MobileMenu() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const location = useLocation();

    const { t } = useTranslation();

    return (
        <div>
            <div className="relative z-[999] w-[30px] h-[25px] cursor-pointer flex flex-col justify-between" onClick={toggleMenu}>
                <div className={`w-full h-[1px] transition duration-300 transform ${isOpen ? 'rotate-45 translate-y-[9px] bg-[#000] w-[35px]' : 'bg-[#000]'}`}></div>
                <div className={`w-full h-[1px] bg-[#000] transition duration-300 transform ${isOpen ? 'hidden' : 'block'}`}></div>
                <div className={`w-full h-[1px]  transition duration-300 transform ${isOpen ? '-rotate-45 -translate-y-[15px] bg-[#000] w-[35px]' : 'bg-[#000]'}`}></div>
            </div>
            <div className={`fixed top-0 right-0 bottom-0 w-full md:w-1/2 bg-white z-50 transform transition-all duration-300 ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}>
                <div className="flex flex-col items-center mt-[50px] gap-[30px]">
                    <img src={logo} alt="" className="w-[40px]"/>
                    <Link to="/tradinginstruments"><p className={`text-[18px] inter ${location.pathname === '/tradinginstruments' ? 'font-medium' : 'font-light'}`} onClick={toggleMenu}>{t('header.1')}</p></Link>
                    <Link to="/fees"><p className={`text-[18px] inter ${location.pathname === '/fees' ? 'font-medium' : 'font-light'}`} onClick={toggleMenu}>{t('header.2')}</p></Link>
                    <Link to="/account"><p className={`text-[18px] inter ${location.pathname === '/account' ? 'font-medium' : 'font-light'}`} onClick={toggleMenu}>{t('header.3')}</p></Link>
                    <Link to="/support"><p className={`text-[18px] inter ${location.pathname === '/support' ? 'font-medium' : 'font-light'}`} onClick={toggleMenu}>{t('header.4')}</p></Link>
                    <Link to="/about"><p className={`text-[18px] inter ${location.pathname === '/about' ? 'font-medium' : 'font-light'}`} onClick={toggleMenu}>{t('header.5')}</p></Link> 
                    <div className="flex items-center gap-[5px]">
                        <Link to="/edu"><p className={`text-[18px] textcolor roboto ${location.pathname === '/edu' ? 'font-medium' : 'font-light'}`}>{t('header.edu')}</p></Link>
                        <img src={lock} alt="" />
                    </div>
                    <Link to="https://user.snb-capital.limited/login"> 
                        <div className="buttonheader cursor-pointer">
                            <p className="rubik text-white text-[16px]">{t('header.6')}</p>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default MobileMenu;