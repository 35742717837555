import React from "react";

import { useTranslation } from "react-i18next";

import pdf from '../../img/AboutUs/pdf.png'

const data = [
    {
      pdf: '/docs/clientmoney.docx',
      text: 'dop.3'  
    },
    {
        pdf: '/docs/keyinfo.pdf',
        text: 'dop.4'  
    },
    {
        pdf: '/docs/managingconflicts.pdf',
        text: 'dop.5'  
    },
    {
        pdf: '/docs/risk.pdf',
        text: 'dop.6'  
    },
    {
        pdf: '/docs/riskdisclosure.docx',
        text: 'dop.7'  
    },    
    {
        pdf: '/docs/orderexecution.pdf',
        text: 'dop.8'  
    },
    {
        pdf: '/docs/websiteterms.pdf',
        text: 'dop.9'  
    },
    {
        pdf: '/docs/tarif.pdf',
        text: 'dop.10'  
    },
    {
        pdf: '/docs/termsofbusiness.pdf',
        text: 'dop.11'  
    },
]

function Regulation () {

    const { t } = useTranslation();
    
    return(
        <div className="">
           <div className="max-w-[1280px] mx-[20px] xl:mx-auto">
               <div className="flex flex-col">
                <p className="text-[#637BFF] open-sans font-[800] text-[18px] xl:text-[30px] mx-auto">{t('dop.1')}</p>
                <p className="max-w-[525px] open-sans text-black font-light text-[14px] xl:text-[16px] mt-[15px] mx-auto text-center">{t('dop.2')}</p>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 mt-[40px] xl:mt-[100px] gap-y-[50px]">
                    {data.map((dat, index) => (
                        <a href={dat.pdf} key={index}>
                            <div className="flex items-center gap-[25px]"> 
                                <img src={pdf} alt="" />
                                <p className="open-sans font-[300] text-[14px] xl:text-[16px]">{t(dat.text)}</p>
                            </div>
                        </a>
                    ))}
                </div>
               </div>
           </div>
        </div>
    );
}

export default Regulation;