import React, { useState } from "react";

import img from '../../img/Home/img.png';

import { useTranslation } from "react-i18next";

import Fade from "react-reveal/Fade";

import whychoose1 from '../../img/Home/whychoose1.png'
import whychoose2 from '../../img/Home/whychoose2.png'

function WhyChoose () {

    const [activeIndex, setactiveIndex] = useState(0);

    const { t } = useTranslation();

    return(
        <div>
           <div className="max-w-[1280px] mx-auto">
                <div className="flex flex-col mx-[20px] xl:mx-0">
                    <div className="flex items-end">
                        <div className="bg-black w-[100px] xl:w-[174px] h-px ml-auto mb-[10px]"></div>
                        <p style={{ color: activeIndex === 0 ? 'black' : '#999999' }} className={`transition-all cursor-pointer text-[20px] xl:text-[25px] open-sans ml-[20px]`} onClick={() => setactiveIndex(0)}>1</p>
                        <p style={{ color: activeIndex === 1 ? 'black' : '#999999' }} className={`transition-all cursor-pointer text-[20px] xl:text-[25px] open-sans ml-[30px]`} onClick={() => setactiveIndex(1)}>2</p>
                    </div>
                    <div className="flex justify-end">
                        <img src={whychoose2} alt="" className="w-[80px] h-[80px] absolute xl:hidden block"/>
                    </div>
                    <div className="flex flex-col xl:flex-row mt-[40px] gap-[30px] justify-center">
                        <div className="gap-[20px] flex flex-col-reverse xl:flex-col">
                            <div className="xl:bg-[#7A8FFF] rounded-[50px] xl:w-[523px] xl:h-[250px] xl:flex items-center">
                                {activeIndex === 0 && (
                                    <Fade><div className="mx-0 xl:mx-[40px] mt-[30px] xl:mt-0">
                                        <p className="open-sans text-[16px] xl:text-[18px] font-[600]">{t('main.5')}</p>
                                        <p className="open-sans text-[14px] xl:text-[16px] font-[300] mt-[15px]">{t('main.6')}</p>
                                    </div></Fade>
                                )}
                                {activeIndex === 1 && (
                                    <Fade><div className="mx-0 xl:mx-[40px] mt-[30px] xl:mt-0">
                                        <p className="open-sans text-[16px] xl:text-[18px] font-[600]">{t('main.7')}</p>
                                        <p className="open-sans text-[14px] xl:text-[16px] font-[300] mt-[15px]">{t('main.8')}</p>
                                    </div></Fade>
                                )}
                            </div>
                            <div className="rounded-[50px] xl:w-[523px] xl:h-[345px] flex xl:items-end xl:justify-center">
                                <Fade bottom><p className="max-w-[423px] absolute text-[#637BFF] xl:text-white text-[18px] xl:text-[22px] open-sans font-[800] mb-[30px] z-50">{t('main.9')}</p></Fade>
                                <div className="whychoose w-[523px] h-[155px] absolute hidden xl:block"></div>
                                <img src={img} alt="" className="hidden xl:block"/>
                            </div>
                        </div>
                        <div className="xl:gap-[20px] flex flex-col">
                            <div className="xl:bg-[#F89CC7] rounded-[50px] xl:w-[667px] xl:h-[346px]">
                                <div className="xl:flex justify-end mr-[-50px] hidden">
                                <Fade right duration={1500}><img src={whychoose1} alt="" className="absolute"/></Fade>
                                    <Fade right duration={4000}><img src={whychoose2} alt="" className="absolute mt-[100px]"/></Fade>
                                </div>
                                {activeIndex === 0 && (
                                    <Fade delay={300}><div className="xl:ml-[40px] max-w-[522px] xl:mt-[40px]">
                                        <p className="open-sans text-[16px] xl:text-[18px] font-[600]">{t('main.10')}</p>
                                        <p className="open-sans text-[14px] xl:text-[16px] font-[300] mt-[15px]">{t('main.11')}</p>
                                    </div></Fade>
                                )}
                                {activeIndex === 1 && (
                                    <Fade delay={300}><div className="xl:ml-[40px] max-w-[522px] xl:mt-[40px]">
                                        <p className="open-sans text-[16px] xl:text-[18px] font-[600]">{t('main.12')}</p>
                                        <p className="open-sans text-[14px] xl:text-[16px] font-[300] mt-[15px]">{t('main.13')}</p>
                                    </div></Fade>
                                )}
                            </div>
                            <div className="xl:bg-[#2E2D2B] rounded-[50px] xl:w-[667px] xl:h-[249px]">
                                {activeIndex === 0 && (
                                    <Fade delay={600}><div className="xl:ml-[40px] max-w-[522px] mt-[40px]">
                                        <p className="open-sans text-[16px] xl:text-[18px] font-[600] xl:text-[#FFF]">{t('main.14')}</p>
                                        <p className="open-sans text-[14px] xl:text-[16px] font-[300] mt-[15px] xl:text-[#FFF]">{t('main.15')}</p>
                                    </div></Fade>
                                )}
                                {activeIndex === 1 && (
                                    <Fade delay={600}><div className="xl:ml-[40px] max-w-[522px] mt-[40px]">
                                        <p className="open-sans text-[16px] xl:text-[18px] font-[600] xl:text-[#FFF]">{t('main.16')}</p>
                                        <p className="open-sans text-[14px] xl:text-[16px] font-[300] mt-[15px] xl:text-[#FFF]">{t('main.17')}</p>
                                    </div></Fade>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
           </div>
        </div>
    );
}

export default WhyChoose;