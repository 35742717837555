import React, { useEffect } from "react";

import LazyLoad from "react-lazyload";

import Main from '../components/TradingInstruments/Main'
import JoinSynth from "../components/Home/JoinSynth";
import Switcher from "../components/TradingInstruments/Switcher";
import TradingTools from "../components/TradingInstruments/TradingTools";
import TradingBonuses from "../components/TradingInstruments/TradingBonuses";
import PassiveIncome from "../components/TradingInstruments/PassiveIncome";


function TradingInstruments () {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div className="pt-[80px]">
            <Main/>
            <div className="mt-[80px] xl:mt-[260px]"></div>
            <LazyLoad><Switcher/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
            <LazyLoad><TradingTools/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
            <LazyLoad><TradingBonuses/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
            <LazyLoad><PassiveIncome/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
            <LazyLoad><JoinSynth/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
        </div>
    );
}

export default TradingInstruments;