import React, { useState } from "react";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from "swiper/modules";

import { useTranslation } from "react-i18next";

import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css';

import img from '../../img/TradingInstruments/img2.png'

import swiper1 from '../../img/TradingInstruments/swiper1.png'
import swiper2 from '../../img/TradingInstruments/swiper2.png'
import swiper3 from '../../img/TradingInstruments/swiper3.png'
import swiper4 from '../../img/TradingInstruments/swiper4.png'
import swiper5 from '../../img/TradingInstruments/swiper5.png'
import swiper6 from '../../img/TradingInstruments/swiper6.png'
import swiper7 from '../../img/TradingInstruments/swiper7.png'
import swiper8 from '../../img/TradingInstruments/swiper8.png'

const slidesData = [
    {
        id: 1,
        title: 'trading.28',
        text: 'trading.29',
        flex: 'flex-col',
        img: swiper1
    },
    {
        id: 2,
        title: 'trading.30',
        text: 'trading.31',
        flex: 'flex-col-reverse',
        img: swiper2
    },
    {
        id: 3,
        title: 'trading.32',
        text: 'trading.33',
        flex: 'flex-col',
        img: swiper3
    },
    {
        id: 4,
        title: 'trading.34',
        text: 'trading.35',
        flex: 'flex-col-reverse',
        img: swiper4
    },
    {
        id: 5,
        title: 'trading.36',
        text: 'trading.37',
        flex: 'flex-col',
        img: swiper5
    },
    {
        id: 6,
        title: 'trading.38',
        text: 'trading.39',
        flex: 'flex-col-reverse',
        img: swiper6
    },
    {
        id: 7,
        title: 'trading.40',
        text: 'trading.41',
        flex: 'flex-col',
        img: swiper7
    },
    {
        id: 8,
        title: 'trading.42',
        text: 'trading.43',
        flex: 'flex-col-reverse',
        img: swiper8
    },
  ];

function TradingTools () {

    const [swiper, setSwiper] = useState({});

    const { t } = useTranslation();

    return(
        <div>
           <div className="max-w-[1280px] mx-auto">
                <div className="flex flex-col xl:flex-row justify-between mx-[20px] xl:mx-0">
                    <div>
                        <p className="text-[#637BFF] open-sans font-[800] text-[18px] xl:text-[30px]">{t('trading.25')}</p>
                        <p className="text-[14px] xl:text-[16px] open-sans font-[300] xl:max-w-[525px] mt-[20px] xl:mt-[30px]">{t('trading.26')}</p>
                        <p className="text-[14px] xl:text-[16px] open-sans font-[300] xl:max-w-[525px] mt-[15px]">{t('trading.27')}</p>
                    </div>
                    <div>
                        <img src={img} alt="" className="mt-[20px] xl:mt-0 mx-auto"/>
                    </div>
                </div>
           </div>
           <div className="mt-[25px] xl:mt-[50px] mx-[20px] xl:mx-0">
                <div className='max-w-[1280px] mx-auto flex justify-end mt-[20px] gap-[10px]'>
                    <div onClick={() => swiper.slidePrev()} className="cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                            <rect x="39.5" y="39.5" width="39" height="39" rx="19.5" transform="rotate(-180 39.5 39.5)" stroke="black"/>
                            <path d="M23 11.9995L15 19.7268L23 26.9995" stroke="black"/>
                        </svg>
                    </div>
                    <div onClick={() => swiper.slideNext()} className="cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                            <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="black"/>
                            <path d="M17 28L25 20.2727L17 13" stroke="black"/>
                        </svg>
                    </div>
                </div>
                <Swiper
                    modules={[Navigation]}
                    spaceBetween={10}
                    slidesPerView={1}
                    loop={true}
                    onInit={(e) => {
                        setSwiper(e);
                    }}
                    centeredSlides={true}
                    className="mt-[15px] xl:mt-[25px]"
                    breakpoints={{
                        300: {
                            slidesPerView: 1,
                        },
                        768: {
                            slidesPerView: 2,
                        },
                        1280: {
                            slidesPerView: 3.4,
                        },
                        1680: {
                            slidesPerView: 5.4,
                        }
                    }}
                >
                    {slidesData.map((slide) => (
                        <SwiperSlide key={slide.id}>
                            <div className={`md:w-[314px] h-[430px] xl:h-[547px] bg-[#F7F9FF] rounded-[50px] py-[35px] px-[35px] flex ${slide.flex}`}>
                                <div>
                                    <p className="max-w-[160px] open-sans font-[600] text-[14px] xl:text-[18px] leading-[1.5]">{t(slide.title)}</p>
                                    <p className="text-[14px] xl:text-[16px] font-[300] mt-[30px] open-sans leading-[1.5]">{t(slide.text)}</p>
                                </div>
                                <img src={slide.img} alt="" className={`mx-auto ${slide.flex === 'flex-col' ? 'mt-auto' : 'mb-auto'}`}/>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
}

export default TradingTools;