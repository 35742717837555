import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import arrow from '../../img/TradingInstruments/arrow.svg'

const faqData = [
    {
        question: 'trading.5',
        answer: 'trading.6'
    },
    {
        question: 'trading.7',
        answer: 'trading.8'
    },
    {
        question: 'trading.9',
        answer: 'trading.10'
    },
    {
        question: 'trading.11',
        answer: 'trading.12'
    },
    {
        question: 'trading.13',
        answer: 'trading.14'
    },
    {
        question: 'trading.15',
        answer: 'trading.16'
    },
    {
        question: 'trading.17',
        answer: 'trading.18'
    },
    {
        question: 'trading.19',
        answer: 'trading.20'
    },
    {
        question: 'trading.21',
        answer: 'trading.22'
    },
    {
        question: 'trading.23',
        answer: 'trading.24'
    },
]

function Switcher () {

    const [openIndex, setOpenIndex] = useState(0);

    const toggleText = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const { t } = useTranslation();

    return(
        <div>
           <div className="max-w-[1280px] mx-auto">
                <div className="grid grid-cols-1 xl:grid-cols-2 gap-x-[170px]">
                    {faqData.map((faq, index) => (
                        <div key={index}>
                            <div className={`border-b-[1px] border-[#00000050] flex justify-between mt-[20px] transition-all duration-300 ${openIndex === index ? 'h-auto' : 'h-[54px]'}`}>
                                <div className='mx-[25px]'>
                                    <p className='mt-[15px] open-sans text-[14px] xl:text-[16px] text-[#000] max-w-[200px] xl:max-w-[1280px]'>{t(faq.question)}</p>
                                    {openIndex === index && (
                                    <p className='textcolor open-sans text-[14px] xl:text-[16px] font-light mt-[12px] pb-[20px] max-w-[200px] md:max-w-max xl:max-w-[500px]'>{t(faq.answer)}</p>
                                    )}
                                </div>
                                <div className='cursor-pointer mx-[25px] mt-[7px]' onClick={() => toggleText(index)}>
                                    <img src={arrow} alt="" className={`${openIndex === index ? 'transition-transform' : 'transition-transform -rotate-90'}`}/>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
           </div>
        </div>
    );
}

export default Switcher;