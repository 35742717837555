import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from "swiper/modules";

import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css';

import swiper1 from '../../img/AccountTypes/swiper1.png'
import swiper2 from '../../img/AccountTypes/swiper2.png'
import swiper3 from '../../img/AccountTypes/swiper3.png'
import swiper4 from '../../img/AccountTypes/swiper4.png'
import swiper5 from '../../img/AccountTypes/swiper5.png'

const slidesData = [
    {
        id: 1,
        title: 'accounttypes.43',
        text:  'accounttypes.44',
        img: swiper1
    },
    {
        id: 2,
        title: 'accounttypes.45',
        text:  'accounttypes.46',
        img: swiper2
    },
    {
        id: 3,
        title: 'accounttypes.47',
        text:  'accounttypes.48',
        img: swiper3
    },
    {
        id: 4,
        title: 'accounttypes.49',
        text:  'accounttypes.50',
        img: swiper4
    },
    {
        id: 5,
        title: 'accounttypes.51',
        text:  'accounttypes.52',
        img: swiper5
    },
  ];

function WhatTypeShould () {

    const [isHovered, setIsHovered] = useState(false);
    const handleMouseEnter = () => {
        setIsHovered(true);
      };
    
      const handleMouseLeave = () => {
        setIsHovered(false);
      };

    const [swiper, setSwiper] = useState({});

    const { t } = useTranslation();

    return(
        <div className="mx-[20px] xl:mx-auto">
            <div className="max-w-[1280px] mx-auto">
                <p className="text-[#637BFF] open-sans font-[800] text-[18px] xl:text-[30px] text-center mx-auto">{t('accounttypes.41')}</p>
                <p className="text-[14px] xl:text-[16px] open-sans font-[300] xl:max-w-[525px] mt-[20px] xl:mt-[30px] text-center mx-auto">{t('accounttypes.42')}</p>           
                <div className='max-w-[1220px] mx-auto flex justify-end mt-[20px] gap-[10px]'>
                    <div onClick={() => swiper.slidePrev()} className="cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                            <rect x="39.5" y="39.5005" width="39" height="39" rx="19.5" transform="rotate(-180 39.5 39.5005)" stroke="black"/>
                            <path d="M23 12L15 19.7273L23 27" stroke="black"/>
                        </svg>
                    </div>
                    <div onClick={() => swiper.slideNext()} className="cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                            <rect x="0.5" y="0.500488" width="39" height="39" rx="19.5" stroke="black"/>
                            <path d="M17 28.0005L25 20.2732L17 13.0005" stroke="black"/>
                        </svg>
                    </div>
                </div>
            </div>
            <div>
                <Swiper
                    modules={[Navigation]}
                    spaceBetween={10}
                    slidesPerView={1}
                    loop={true}
                    onInit={(e) => {
                        setSwiper(e);
                    }}
                    centeredSlides={true}
                    className="mt-[20px]"
                    breakpoints={{
                        300: {
                            slidesPerView: 1
                        },
                        1650: {
                            slidesPerView: 1.5,
                        },
                    }}
                >
                    {slidesData.map((slide) => (
                        <SwiperSlide key={slide.id}>
                            <div className="xl:w-[1220px] h-[400px] bg-[#F89CC7] rounded-[50px] flex mx-auto">
                                <div className="hidden xl:block">
                                    <img src={slide.img} alt="" />
                                </div>
                                <div className="xl:mx-[50px] mx-[20px] mt-[50px]">
                                    <p className="text-[#181719] roboto font-[900] text-[14px] xl:text-[25px]">{t(slide.title)}</p>
                                    <p className="mt-[15px] xl:mt-[40px] max-w-[525px] font-[300] text-[14px] xl:text-[16px] roboto">• {t(slide.text)}</p>
                                    <a href="https://user.snb-capital.limited/login">
                                    <div 
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                        className="flex w-[167px] buttonswiper bottom-[30px] absolute cursor-pointer items-center"
                                    >
                                        <div style={{ width: isHovered ? '100%' : '38.774px' }} className={`z-50 transition-all duration-500 h-[38.774px] rounded-full bg-[#FFF] flex items-center justify-center`}>
                                            <svg className="transition-all duration-500" style={{ rotate: isHovered ? '-135deg' : '0deg' }} xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                                <g clip-path="url(#clip0_1175_524)">
                                                    <path d="M8.04175 11.7496L14.9945 4.79688L16.1367 5.93911L9.18398 12.8919H15.312V14.5075H6.42615V5.62164H8.04175V11.7488V11.7496Z" fill="black"/>
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_1175_524">
                                                        <rect width="19.3872" height="19.3872" fill="white" transform="translate(0.771606 0.771484)"/>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                        <p className={`text-[#FFF] roboto text-[16px] ml-[45px] absolute`}>{t('trading.64')}</p>
                                    </div>
                                    </a>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
}

export default WhatTypeShould;