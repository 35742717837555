import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import arrow from '../../img/TradingInstruments/arrow.svg'

const faqData = [
    {
        question: 'supportfaq.8',
        answer: 'supportfaq.9'
    },
    {
        question: 'supportfaq.10',
        answer: 'supportfaq.11'
    },
    {
        question: 'supportfaq.12',
        answer: 'supportfaq.13'
    },
    {
        question: 'supportfaq.14',
        answer: 'supportfaq.15'
    },
    {
        question: 'supportfaq.16',
        answer: 'supportfaq.17'
    },
]

function HowWeCanHelp () {

    const [isHovered, setIsHovered] = useState(false);
    const handleMouseEnter = () => {
        setIsHovered(true);
      };
    
      const handleMouseLeave = () => {
        setIsHovered(false);
      };

    const [openIndex, setOpenIndex] = useState(0);

    const toggleText = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const { t } = useTranslation();

    return(
        <div>
           <div className="max-w-[1280px] xl:mx-auto flex flex-col xl:flex-row justify-between mx-[20px]">
                <div>
                    <p className="text-[#637BFF] open-sans font-[800] text-[18px] xl:text-[30px]">{t('supportfaq.5')}</p>
                    <p className="text-[14px] xl:text-[16px] open-sans font-[300] max-w-[525px] mt-[20px] xl:mt-[30px]">{t('supportfaq.6')}</p>
                    <p className="text-[14px] xl:text-[16px] open-sans font-[300] max-w-[525px] mt-[15px]">{t('supportfaq.7')}</p>
                    <a href="https://user.snb-capital.limited/login">
                    <div 
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        className="flex w-[200px] buttonmain cursor-pointer mt-[40px] items-center"
                    >
                        <div style={{ width: isHovered ? '100%' : '38.774px' }} className={`z-50 transition-all duration-500 h-[38.774px] rounded-full bg-[#181719] flex items-center justify-center`}>
                            <svg className="transition-all duration-500" style={{ rotate: isHovered ? '-135deg' : '0deg' }} xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                <g clip-path="url(#clip0_1175_524)">
                                    <path d="M8.04175 11.7496L14.9945 4.79688L16.1367 5.93911L9.18398 12.8919H15.312V14.5075H6.42615V5.62164H8.04175V11.7488V11.7496Z" fill="white"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_1175_524">
                                        <rect width="19.3872" height="19.3872" fill="white" transform="translate(0.771606 0.771484)"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <p className={`text-[#080808] roboto text-[16px] ml-[45px] absolute`}>{t('supportfaq.4')}</p>
                    </div>
                    </a>
                </div>
                <div className="mt-[20px] xl:mt-0">
                    <div>
                        {faqData.map((faq, index) => (
                            <div key={index}>
                                <div onClick={() => toggleText(index)} className={`xl:w-[592px] flex mt-[20px] transition-all duration-300 ${openIndex === index ? 'bg-[#F89CC7] rounded-[20px] h-auto' : 'h-[54px] hover:bg-[#DADADA] rounded-[20px] cursor-pointer'}`}>
                                    <div className='cursor-pointer mt-[15px] ml-[20px]'>
                                        <img src={arrow} alt="" className={`${openIndex === index ? 'transition-transform' : 'transition-transform -rotate-90'}`}/>
                                    </div>
                                    <div className='mx-[10px]'>
                                        <p className='font-[600] mt-[15px] open-sans text-[14px] xl:text-[16px] text-[#000] max-w-[200px] xl:max-w-[1280px]'>{t(faq.question)}</p>
                                        {openIndex === index && (
                                        <p className='textcolor open-sans text-[14px] xl:text-[16px] font-light mt-[12px] pb-[20px] max-w-[200px] xl:max-w-[500px]'>{t(faq.answer)}</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
           </div>
        </div>
    );
}

export default HowWeCanHelp;