import React from "react";

import join1 from '../../img/Home/join1.png'
import join2 from '../../img/Home/join2.png'
import join3 from '../../img/Home/join3.png'

import { useTranslation } from "react-i18next";

function JoinSynth () {

    const { t } = useTranslation();

    return(
        <div>
           <div className="max-w-[1280px] mx-auto">
                <p className="text-[#637BFF] open-sans font-[800] text-[18px] xl:text-[30px] text-center mx-[20px] xl:mx-0">{t('main.46')}</p>
                <div className="grid grid-cols-1 xl:grid-cols-3 mt-[30px] xl:mt-[65px]">
                    <div className="flex flex-col justify-center items-center mt-[30px] xl:mt-0">
                        <img src={join1} alt="" />
                        <p className="text-[14px] xl:text-[25px] font-[800] text-[#222B40]">{t('main.47')}</p>
                        <p className="text-[14px] xl:text-[16px] text-[#222B40] max-w-[150px] text-center mt-[10px]">{t('main.48')}</p>
                    </div>
                    <div className="flex flex-col justify-center items-center mt-[30px] xl:mt-0">
                        <img src={join2} alt="" />
                        <p className="text-[14px] xl:text-[25px] font-[800] text-[#222B40]">{t('main.49')}</p>
                        <p className="text-[14px] xl:text-[16px] text-[#222B40] max-w-[242px] text-center mt-[10px]">{t('main.50')}</p>
                    </div>
                    <div className="flex flex-col justify-center items-center mt-[30px] xl:mt-0">
                        <img src={join3} alt="" />
                        <p className="text-[14px] xl:text-[25px] font-[800] text-[#222B40]">{t('main.51')}</p>
                        <p className="text-[14px] xl:text-[16px] text-[#222B40] max-w-[188px] text-center mt-[10px]">{t('main.52')}</p>
                    </div>
                </div>
           </div>
        </div>
    );
}

export default JoinSynth;