import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import arrow from '../../img/TradingInstruments/arrow.svg'

const faqData = [
    {
        question: 'supportfaq.63',
        answer: 'supportfaq.64'
    },
    {
        question: 'supportfaq.65',
        answer: 'supportfaq.66'
    },
    {
        question: 'supportfaq.67',
        answer: 'supportfaq.68'
    },
    {
        question: 'supportfaq.69',
        answer: 'supportfaq.70'
    },
    {
        question: 'supportfaq.71',
        answer: 'supportfaq.72'
    },
    {
        question: 'supportfaq.73',
        answer: 'supportfaq.74'
    },
    {
        question: 'supportfaq.75',
        answer: 'supportfaq.76'
    },
]

function PlatformWalkthroughsSecond () {

    const { t } = useTranslation();

    const [openIndex, setOpenIndex] = useState(0);

    const toggleText = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return(
        <div>
           <div className="max-w-[1280px] mx-auto">
                <p className="text-[#637BFF] open-sans font-[800] text-[18px] xl:text-[30px] text-center mx-[20px] xl:mx-auto">{t('supportfaq.77')}</p>
                <p className="text-[14px] xl:text-[16px] open-sans font-[300] xl:max-w-[525px] mt-[20px] xl:mt-[30px] text-center mx-[20px] xl:mx-auto">{t('supportfaq.78')}</p>                          
                <div className="flex flex-col max-w-[800px] mx-auto mt-[30px] xl:mt-[65px]">
                    {faqData.map((faq, index) => (
                        <div key={index}>
                            <div className={`border-b-[1px] border-[#00000050] flex justify-between mt-[20px] transition-all duration-300 ${openIndex === index ? 'h-auto' : 'h-[54px]'}`}>
                                <div className='mx-[25px] flex flex-col justify-center mt-[-15px]'>
                                    <p className='open-sans text-[14px] xl:text-[16px] text-[#000] max-w-[300px] xl:max-w-[1280px]'>{t(faq.question)}</p>
                                    {openIndex === index && (
                                    <p className='textcolor open-sans text-[14px] xl:text-[16px] font-light mt-[12px] pb-[20px] max-w-[200px] md:max-w-max xl:max-w-[500px]'>{t(faq.answer)}</p>
                                    )}
                                </div>
                                <div className='cursor-pointer mx-[25px] mt-[7px]' onClick={() => toggleText(index)}>
                                    <img src={arrow} alt="" className={`${openIndex === index ? 'transition-transform' : 'transition-transform -rotate-90'}`}/>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
           </div>
        </div>
    );
}

export default PlatformWalkthroughsSecond;