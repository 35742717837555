import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import img1 from '../../img/FeesCommissions/1.png'
import img2 from '../../img/FeesCommissions/2.png'
import img3 from '../../img/FeesCommissions/3.png'
import img4 from '../../img/FeesCommissions/4.png'
import img5 from '../../img/FeesCommissions/5.png'

import image from '../../img/FeesCommissions/image.png'

const data = [
    {
        name: 'add.7',
        text: [
            'add.8',
            'add.9',
            'add.10',
            'add.11',
            'add.12'
        ]
    },
    {
        name: 'add.13',
        text: [
            'add.14',
            'add.15',
            'add.16',
            'add.17',
            'add.18'
        ]
    },
    {
        name: 'add.19',
        text: [
            'add.20',
            'add.21',
            'add.22',
            'add.23',
            'add.24'
        ]
    },
    {
        name: 'add.25',
        text: [
            'add.26',
            'add.27',
            'add.28',
            'add.29',
            'add.30'
        ]
    },
    {
        name: 'add.31',
        text: [
            'add.32',
            'add.33',
            'add.34',
            'add.35',
            'add.36'
        ]
    },
]

function AccountBased () {

    const { t } = useTranslation();

    const [activeIndex, setactiveIndex] = useState(0);

    const handleSlideClick = (index) => {
        setactiveIndex(index);
    };

    const [isHovered, setIsHovered] = useState(false);
    const handleMouseEnter = () => {
        setIsHovered(true);
      };
    
      const handleMouseLeave = () => {
        setIsHovered(false);
      };

    return(
        <div className="max-w-[1280px] mx-auto">
            <div className="h-[750px] md:h-[601px] bg-[#000] xl:rounded-[50px] flex flex-col relative overflow-hidden xl:overflow-visible">
                <img src={img1} alt="" className="absolute top-[-50px] left-[60px] hidden xl:block"/>
                <img src={img2} alt="" className="absolute left-[300px] top-[-20px]"/>
                <img src={img3} alt="" className="absolute right-[30px] top-[20px] hidden xl:block"/>
                <img src={img4} alt="" className="absolute top-[180px] left-[20px] xl:left-auto xl:right-[20px]"/>
                <img src={img5} alt="" className="absolute bottom-0 left-[275px]"/>
                <p className="mx-auto mt-[50px] xl:mt-[65px] text-[#A8B5F9] text-[20px] xl:text-[30px] font-[800] open-sans">{t('add.37')}</p>
                <p className="md:mx-auto mt-[15px] xl:mt-[30px] open-sans font-[300] text-[14px] xl:text-[16px] text-center max-w-[525px] text-white mx-[20px]">{t('add.38')}</p>
                <div className="flex flex-col xl:flex-row justify-between mx-[20px] xl:mx-[70px] mt-[30px] xl:mt-[60px] relative z-50">
                    <div className="flex flex-row xl:flex-col flex-wrap gap-[10px] justify-center xl:justify-start">
                        {data.map((data, index) => (
                            <div key={index} className={`flex items-center justify-center transition-all duration-500 button-account-based border border-[#FFF3] rounded-[10px] cursor-pointer hover:bg-[#FFF2] ${ activeIndex === index ? 'bglinear' : '' }`} onClick={() => handleSlideClick(index)}>
                                <p className={`transition-all duration-700 ${ activeIndex === index ? 'text-black' : 'text-white' }`}>{t(data.name)}</p>
                            </div>
                        ))}
                    </div>
                    <div className="flex flex-col gap-[15px] xl:w-[525px] mt-[20px] xl:mt-0">
                        {data[activeIndex].text.map((item, index) => (
                            <p key={index} className="text-white open-sans font-[300] open-sans">{t(item)}</p>                 
                        ))}
                    </div>
                </div>
            </div>
            <div className="flex flex-col xl:flex-row justify-between mt-[80px] xl:mt-[160px] mx-[20px]">
                <div>
                    <p className="text-[14px] xl:text-[16px] open-sans font-[300] xl:max-w-[525px]">{t('add.49')}</p>
                    <p className="text-[14px] xl:text-[16px] open-sans font-[300] xl:max-w-[525px] mt-[15px] xl:mt-[30px]">{t('add.40')}</p>
                    <p className="text-[14px] xl:text-[16px] open-sans font-[300] xl:max-w-[525px] mt-[15px] xl:mt-[30px]">{t('add.41')}</p>
                    <a href="https://user.snb-capital.limited/login">
                    <div 
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        className="flex w-[200px] buttonmain cursor-pointer mt-[40px] items-center"
                    >
                        <div style={{ width: isHovered ? '100%' : '38.774px' }} className={`z-50 transition-all duration-500 h-[38.774px] rounded-full bg-[#181719] flex items-center justify-center`}>
                            <svg className="transition-all duration-500" style={{ rotate: isHovered ? '-135deg' : '0deg' }} xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                <g clip-path="url(#clip0_1175_524)">
                                    <path d="M8.04175 11.7496L14.9945 4.79688L16.1367 5.93911L9.18398 12.8919H15.312V14.5075H6.42615V5.62164H8.04175V11.7488V11.7496Z" fill="white"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_1175_524">
                                        <rect width="19.3872" height="19.3872" fill="white" transform="translate(0.771606 0.771484)"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <p className={`text-[#080808] roboto text-[16px] ml-[45px] absolute`}>{t('add.42')}</p>
                    </div>
                    </a>
                </div>
                <div className="xl:mt-[-50px]">
                    <img src={image} alt="" className="mx-auto"/>
                </div>
            </div>
        </div>
    );
}

export default AccountBased;