import React, { useState, useEffect } from "react";
import classes from './Schedule.module.scss';
import { useTranslation } from "react-i18next";
import axios from "axios";

const rows = [
    {
        col_01: 'education_text_01_row_01',
        col_02: '16:30',
    },
    {
        col_01: 'education_text_01_row_02',
        col_02: '17:30',
    },
    {
        col_01: 'education_text_01_row_03',
        col_02: '18:30',
    },
];

const Schedule = () => {

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };
    
    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const { t } = useTranslation();
    const [ domLoaded, setDomLoaded ] = useState(false);
    const [ webinarLink, setWebinarLink ] = useState('#');

    const getSheetValues = async () =>{
        try {
            const { data } = await axios.post('https://civbt.xyz/api/bot/pEoOWPNFOo');
            if (data.status === 200) {
                setWebinarLink(data.data);
                return;
            }
        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        if (domLoaded) {
            getSheetValues();
        }
        // eslint-disable-next-line
    }, [ domLoaded ]);

    useEffect(() => {
        setDomLoaded(true);
    }, []);

    return (
        <section className="mt container">
            <div className={classes.schedule}>
                <h2 className={`${classes.title} font-48`}>
                    {t('education_title_04')}
                </h2>
                <div className={classes.tableWrap}>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    {t('education_text_01_col_01')}
                                </td>
                                <td>
                                    {t('education_text_01_col_02')}
                                </td>
                            </tr>
                            {rows.map((row, rowIndex) =>
                                <tr key={rowIndex}>
                                    <td>
                                        {t(row.col_01)}
                                    </td>
                                    <td>
                                        {row.col_02}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className={classes.btn}>
                <a href={webinarLink}>
                    <div 
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        className="flex w-[250px] buttonmain cursor-pointer mt-[40px] items-center mx-auto"
                    >
                        <div style={{ width: isHovered ? '100%' : '38.774px' }} className={`z-50 transition-all duration-500 h-[38.774px] rounded-full bg-[#181719] flex items-center justify-center`}>
                            <svg className="transition-all duration-500" style={{ rotate: isHovered ? '-135deg' : '0deg' }} xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                <g clip-path="url(#clip0_1175_524)">
                                    <path d="M8.04175 11.7496L14.9945 4.79688L16.1367 5.93911L9.18398 12.8919H15.312V14.5075H6.42615V5.62164H8.04175V11.7488V11.7496Z" fill="white"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_1175_524">
                                        <rect width="19.3872" height="19.3872" fill="white" transform="translate(0.771606 0.771484)"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <p className={`text-[#080808] roboto text-[16px] ml-[45px] absolute`}>{t('webinar')}</p>
                    </div>
                </a>
                </div>
            </div>
        </section>
    );
};

export default Schedule;
