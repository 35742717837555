import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import main1 from '../../img/Home/main4.png';
import main2 from '../../img/Home/main3.png';
import main3 from '../../img/Home/main2.png';
import main4 from '../../img/Home/main1.png';

import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';

function Main () {

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };
    
    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    
    const { t } = useTranslation();
    
    return(
        <div className="">
           <div className="max-w-[1280px] mx-auto">
                <div className="xl:flex mt-[40px] items-end gap-[200px] hidden ml-[100px]">
                    <Bounce duration={4000}><img src={main1} alt="" className="w-[100.674px] h-[97.162px]"/></Bounce>
                    <Bounce duration={4000} delay={500}><img src={main2} alt="" className="w-[45.45px] h-[36.653px]"/></Bounce>
                </div>
                <div className="xl:h-[459px] flex flex-col xl:flex-row xl:justify-between items-center xl:items-start w-auto bg-black rounded-[50px] xl:mt-[70px] mx-[20px] xl:mx-0">
                    <div className="xl:ml-[70px] mt-[45px] mx-[20px] xl:mx-0">
                        <p className="max-w-[500px] open-sans text-[#A8B5F9] font-extrabold text-[18px] xl:text-[23px]">{t('main.1')}</p>
                        <p className="max-w-[525px] open-sans text-white font-light text-[14px] xl:text-[16px] mt-[30px]">{t('main.2')}</p>
                        <p className="max-w-[525px] open-sans text-white font-light text-[14px] xl:text-[16px] mt-[15px]">{t('main.3')}</p>
                        <a href="https://user.snb-capital.limited/login">
                            <div 
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                                className="flex w-[200px] buttonmain cursor-pointer mt-[40px] items-center"
                            >
                                <div style={{ width: isHovered ? '100%' : '38.774px' }} className={`z-50 transition-all duration-500 h-[38.774px] rounded-full bg-[#181719] flex items-center justify-center`}>
                                    <svg className="transition-all duration-500" style={{ rotate: isHovered ? '-135deg' : '0deg' }} xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                        <g clip-path="url(#clip0_1175_524)">
                                            <path d="M8.04175 11.7496L14.9945 4.79688L16.1367 5.93911L9.18398 12.8919H15.312V14.5075H6.42615V5.62164H8.04175V11.7488V11.7496Z" fill="white"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1175_524">
                                                <rect width="19.3872" height="19.3872" fill="white" transform="translate(0.771606 0.771484)"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                                <p className={`text-[#080808] roboto text-[16px] ml-[45px] absolute`}>{t('main.4')}</p>
                            </div>
                        </a>
                    </div> 
                    <div>
                        <Fade duration={1000}><img src={main3} alt="" className="xl:absolute xl:mt-[-122px] xl:ml-[-150px] relative z-50 mt-[50px]"/></Fade>
                        <Fade bottom duration={4000}><img src={main4} alt="" className="absolute right-0 bottom-0 xl:static"/></Fade>
                    </div>
                </div>
           </div>
        </div>
    );
}

export default Main;