import React, { useEffect } from "react";

import LazyLoad from "react-lazyload";

import Main from '../components/Home/Main'
import WhyChoose from "../components/Home/WhyChoose";
import DiversifyYourPortfolio from "../components/Home/DiversifyYourPortfolio";
import TransparentFees from "../components/Home/TransparentFees";
import SynthTradeAccount from "../components/Home/SynthTradeAccount";
import GetAnyAnswers from "../components/Home/GetAnyAnswers";
import JoinSynth from "../components/Home/JoinSynth";


function Home () {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div className="pt-[80px]">
            <Main/>
            <div className="mt-[80px] xl:mt-[260px]"></div>
            <LazyLoad><WhyChoose/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
            <LazyLoad><DiversifyYourPortfolio/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
            <LazyLoad><TransparentFees/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
            <LazyLoad><SynthTradeAccount/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
            <LazyLoad><GetAnyAnswers/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
            <LazyLoad><JoinSynth/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
        </div>
    );
}

export default Home;