import React, { useEffect } from "react";

import LazyLoad from "react-lazyload";

import Main from '../components/AboutUs/Main'
import JoinSynth from "../components/Home/JoinSynth";
import InfoBlocks from "../components/AboutUs/InfoBlocks";
import OurAchievements from "../components/AboutUs/OurAchievements";
import OurAmbitious from "../components/AboutUs/OurAmbitious";
import Regulation from "../components/AboutUs/Regulation";

function AboutUs () {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div className="pt-[80px]">
            <Main/>
            <div className="mt-[80px] xl:mt-[260px]"></div>
            <LazyLoad><Regulation/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
            <LazyLoad><InfoBlocks/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
            <LazyLoad><OurAchievements/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
            <LazyLoad><OurAmbitious/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
            <LazyLoad><JoinSynth/></LazyLoad>
            <div className="mt-[80px] xl:mt-[160px]"></div>
        </div>
    );
}

export default AboutUs;