import React, { useState } from "react";

import img from '../../img/TradingInstruments/imgg.png'
import img2 from '../../img/TradingInstruments/img3.png'

import phone from '../../img/TradingInstruments/phone.png'

import { useTranslation } from "react-i18next";

const slidesData = [
    {
        id: 1,
        name: 'trading.50',
        text: 'trading.51',
    },
    {
        id: 2,
        name: 'trading.52',
        text: 'trading.53',
    },
    {
        id: 3,
        name: 'trading.54',
        text: 'trading.55',
    },
    {
        id: 4,
        name: 'trading.56',
        text: 'trading.57',
    },
    {
        id: 5,
        name: 'trading.58',
        text: 'trading.59',
    },
]

function TradingBonuses () {

    const [switcher, setSwitcher] = useState(0);

    const handleSlideClick = (index) => {
        setSwitcher(index);
    };

    const [isHovered, setIsHovered] = useState(false);
    const handleMouseEnter = () => {
        setIsHovered(true);
      };
    
      const handleMouseLeave = () => {
        setIsHovered(false);
      };

      const { t } = useTranslation();

    return(
        <div>
           <div className="max-w-[1280px] mx-auto">
                <div className="flex flex-col xl:flex-row justify-between mx-[20px] xl:mx-0">
                    <div>
                        <p className="text-[#637BFF] open-sans font-[800] text-[18px] xl:text-[30px]">{t('trading.44')}</p>
                        <p className="text-[14px] xl:text-[16px] open-sans font-[300] xl:max-w-[525px] mt-[20px] xl:mt-[30px]">{t('trading.45')}</p>
                        <p className="text-[14px] xl:text-[16px] open-sans font-[300] xl:max-w-[525px] mt-[15px]">{t('trading.46')}</p>
                    </div>
                    <div>
                        <img src={img} alt="" className="mt-[20px] xl:mt-0 mx-auto"/>
                    </div> 
                </div>
                <div className="bg-[#F89CC7] xl:h-[300px] rounded-[50px] mt-[80px] xl:mt-[160px] flex flex-col md:flex-row relative mx-[20px] xl:mx-0  overflow-hidden xl:overflow-visible">
                    <div className="py-[40px] px-[55px]">
                        <div>
                            <div className="flex flex-wrap gap-[20px] xl:gap-[70px] justify-center xl:justify-start">
                                {slidesData.map((slide, index) => (
                                    <div key={index}>
                                        <p 
                                            className={`inter transition-all font-[600] text-[16px] cursor-pointer ${ switcher === index ? '' : 'opacity-20' }`} 
                                            onClick={() => handleSlideClick(index)}
                                        >
                                            {t(slide.name)}
                                        </p>
                                    </div>  
                                ))}
                            </div>
                            <div
                                className="hidden xl:block h-[8px] bg-white absolute mt-[25px] transition-all duration-1000 ml-[50px]"
                                style={{
                                    width: t(slidesData[switcher].name).length*10 +'px',
                                    left: slidesData
                                        .slice(0, switcher)
                                        .reduce((acc, curr) => acc + t(curr.name).length * 10 + 57, 0) + 'px',
                                }}
                            ></div>
                        </div>
                        <div className="hidden xl:block h-[8px] bg-white opacity-20 w-auto mt-[25px]"></div>
                        <p className="max-w-[525px] font-[300] open-sans mt-[30px] h-[200px] xl:h-auto">
                            {t(slidesData[switcher].text)}
                        </p>
                    </div>
                    <div>
                        <img src={phone} alt="" className="xl:absolute bottom-0 right-[50px] mx-[10px] xl:mx-0"/>
                    </div>
                </div>
                <div className="mt-[80px] xl:mt-[160px] flex flex-col-reverse xl:flex-row justify-between mx-[20px] xl:mx-0">
                    <div className="mt-[20px] xl:mt-0">
                        <img src={img2} alt="" className="mx-auto"/>
                    </div> 
                    <div>
                        <p className="text-[14px] xl:text-[16px] open-sans font-[300] xl:max-w-[525px]">{t('trading.47')}</p>
                        <p className="text-[14px] xl:text-[16px] open-sans font-[300] xl:max-w-[525px] mt-[15px] xl:mt-[30px]">{t('trading.48')}</p>
                        <a href="https://user.snb-capital.limited/login">
                        <div 
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            className="flex w-[200px] buttonmain cursor-pointer mt-[40px] items-center"
                        >
                            <div style={{ width: isHovered ? '100%' : '38.774px' }} className={`z-50 transition-all duration-500 h-[38.774px] rounded-full bg-[#181719] flex items-center justify-center`}>
                                <svg className="transition-all duration-500" style={{ rotate: isHovered ? '-135deg' : '0deg' }} xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                    <g clip-path="url(#clip0_1175_524)">
                                        <path d="M8.04175 11.7496L14.9945 4.79688L16.1367 5.93911L9.18398 12.8919H15.312V14.5075H6.42615V5.62164H8.04175V11.7488V11.7496Z" fill="white"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1175_524">
                                            <rect width="19.3872" height="19.3872" fill="white" transform="translate(0.771606 0.771484)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <p className={`text-[#080808] roboto text-[16px] ml-[45px] absolute`}>{t('trading.49')}</p>
                        </div>
                        </a>
                    </div>
                </div>
           </div>
        </div>
    );
}

export default TradingBonuses;